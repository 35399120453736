table{
    width:100%;
    border-collapse: collapse;
}

thead{
    font-weight: bold;
}

td{
    width:25%;
}

thead, tr{
    border-bottom:2px solid black;
}

tr:last-child{
    border-bottom: none;
}

td{
    border-right: 2px solid black;
    padding: 10px 0;
}

td:last-child{
    border-right: none;
}

#attendanceSelection{
    margin:auto;
    width:90%;
    display: flex;
    flex-direction: row;
}

#attendanceSelection > div{
    width:33%;
    display:flex;
    flex-direction: column;
}

#attendanceSelection > div > p{
    margin:.5em;
}

h4 {
    margin-bottom: 0px;
}

select {
    width:2.5em;
    margin: .7em auto;
}

#emailText{
    font-size: 25px;
}

#note{
    margin-top:0;
}

#emailInput{
    max-width:275px;
    width:100%;
    display: block;
    margin:10px auto;
    border: 2px solid black;
    height:30px;
    font-size:20px;
}
