#container{
    margin-top:30px;
}

.arrow{
    width:40px;
    position: relative;
    bottom:0;
}

#previousArrow{
    transform:rotate(180deg);
}

#requestsPleasureOfCompany{
    font-size:22px;
    font-family:gabriolaa;
    margin:0;
}
