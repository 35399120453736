#contactEmail{
    font-size:25px;
}

.contactName{
    font-size:25px;
}

.contactNumber{
    font-size:18px;
}

.contactRelationship{
    font-size:18px;
}