#background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

#loader {
    border: 8px solid rgba(0, 0, 0, .2);
    border-top: 8px solid var(--theme-color);
    border-radius: 50%;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}